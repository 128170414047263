import { BeforeAfterSlider } from '@/components/Common/BeforeAfterSlder';
import { ListMarker } from '@/images/svg/ListMarker';
import './styles.scss';
import { Cube } from '@/components/AnimatedPuzzle/Cube';
import { HOLE_SIDE_SIZE, PUZZLE_PIECES } from '@/components/AnimatedPuzzle/constants';
import { Hole } from '@/components/AnimatedPuzzle/Hole';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { useEffect, useRef } from 'react';
import { Arrow } from '@/images/svg/Arrow';
import Link from 'next/link';

export interface IServiceCardProps {
  id: number;
  image?: string;
  bgColor: string;
  slider?: {
    before: string;
    after: string;
  };
  preTitle: string;
  title: string;
  description: string;
  items: string[];
  index: number;
  isTablet: boolean;
  buttonText: string;
  link: string;
}

export const ServiceCard = ({
  index,
  items,
  image,
  slider,
  bgColor,
  description,
  title,
  preTitle,
  isTablet,
  buttonText,
  link,
}: IServiceCardProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isInViewPort = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewPort && ref.current) {
      if (!ref.current.classList.contains('puzzle-animation')) {
        ref.current.classList.add('puzzle-animation');
      }
    }
  }, [isInViewPort]);

  return (
    <div
      className="
       flex justify-center items-center pt-[40px]
       lg-2:sticky lg-2:top-0 lg-2:pt-0
       lg-2:px-[60px] lg-2:h-[100vh] lg-2:max-h-[680px]
       lg-2:mt-[-150px] lg-2:mb-[-150px]
       [@media_((min-width:1180px)_and_(max-height:850px))]:pt-[130px]
       [@media(max-height:850px)]:mb-0
       pointer-events-none
    "
    >
      <div
        className="
          flex flex-col w-full
          lg-2:flex-row
          lg-2:relative lg-2:w-[1108px] lg-2:h-[540px]
          pointer-events-auto
        "
        style={{ top: `calc(7% + ${index * 58}px)` }}
      >
        {image && (
          <div
            ref={ref}
            className={`
              w-[44.3%] h-auto bg-black-11 relative 
              overflow-hidden lg-2:block hidden mask 
            `}
          >
            <div className="scale-[1.25] relative left-[-32px]">
              <div
                className="
                bg-[url(/images/main/dashboard-puzzle.webp)] bg-no-repeat bg-center bg-contain
                puzzle-image
              "
              >
                {PUZZLE_PIECES.map(({ animationDelay, holeOffset }, index) => (
                  <Hole
                    key={index}
                    holeOffset={holeOffset}
                    width={HOLE_SIDE_SIZE}
                    height={HOLE_SIDE_SIZE}
                    animationDelay={animationDelay}
                  />
                ))}
              </div>

              <div className="absolute puzzle-wrapper">
                {PUZZLE_PIECES.map(({ holeOffset, containerOffset, animationDelay, zIndex }, index) => (
                  <Cube
                    key={index}
                    zIndex={zIndex}
                    containerOffset={containerOffset}
                    holeOffset={holeOffset}
                    animationDelay={animationDelay}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {slider && (
          <div
            className="w-[44.3%] flex items-center justify-center"
            style={{ backgroundColor: bgColor }}
          >
            {!isTablet && (
              <BeforeAfterSlider
                beforeImage={slider.before}
                afterImage={slider.after}
              />
            )}
          </div>
        )}
        <div
          className="
           p-[20px] flex flex-col justify-between gap-[10px]
           lg-2:w-[55.7%]
           lg-2:p-[40px]
           xl-mac:pl-[115px] xl-mac:pt-[25px] xl-mac:pb-[40px] xl-mac:pr-[16px]
          "
          style={{ backgroundColor: bgColor }}
        >
          <div>
            {preTitle && <p className="text-purple-1 mb-[7px] font-mono">{preTitle}</p>}
            {title && <h4 className="text-purple-4 mb-[12px] text-[20px] lg-2:text-[30px] leading-[36px]">{title}</h4>}
            {description && (
              <p className="text-purple-1 mb-[16px] lg-2:mb-[36px] lg-2:text-[20px] leading-[28px]">{description}</p>
            )}
            {items && (
              <ul>
                {items.map((item, idx) => {
                  return (
                    <li
                      key={idx}
                      className="flex items-center leading-[24px] text-purple-1 mb-[8px] lg-2:mb-[18px] last:mb-0"
                    >
                      <ListMarker id={`${Math.random()}`} />
                      <span className="ml-[10px]">{item}</span>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <div className="flex w-full justify-end items-center group pr-base">
            <Link
              href={link}
              className="group-hover:opacity-80 duration-300 text-white"
            >
              {buttonText}
            </Link>
            <Arrow
              variant="secondary"
              className="group-hover:translate-x-[5px] duration-300"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
