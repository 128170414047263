'use client';

import data from './data.json';
import cases from '../../../app/our-work/data.json';
import { Loop } from '@/components/Common/Loop';
import { Section } from '@/components/Layouts/Section';
import { ClientsSwiper } from '@/components/Swipers/ClientsSwiper';
import { Services } from '@/components/Common/Services';
import { CollapsableBanner } from '@/components/Banners/CollapsableBanner';
import VideoBackgroundIntro from '@/components/Layouts/Intros/VideoBackgroundIntro';
import { Cases } from '@/components/Common/Cases';
import { MainIntroAB } from '@/components/MainIntro';
import '@/components/MainIntro/index.scss';
import React from 'react';
import ContactFormPopup from '@/components/Common/ContactFormPopup';
import { IndustriesBanner } from '@/components/Banners/IndustriesBanner';
import usePopup from '@/hooks/usePopup';
import { themeColors } from '@/constants/case-studies';
import { NavBar } from '@/components/NavBar';
import FeedbacksSwiperSection from '@/components/01-new/organisms/01-sections/FeedbacksSwiperSection';
import { ContainerWithAside } from '@/components/01-new/atoms/02-layouts/ContainerWithAside';
import { FadeAnimationContainer } from '@/components/01-new/molecules/03-decorations/FadeAnimationContainer';
import { Typography } from '@/components/01-new/atoms/00-ui/Typography';
import { JourneySection } from '@/organisms/01-sections/JourneySection';

export const Home = () => {
  const { handleClosePopup, handleOpenPopup, isOpen } = usePopup();
  const casesList = cases.cases.list;

  return (
    <>
      <ContactFormPopup
        show={isOpen}
        onClose={handleClosePopup}
      />

      <NavBar list={['intro', 'about', 'services', 'journey', 'projects', 'feedbacks', 'contact']} />

      <MainIntroAB
        data={data.intro}
        onButtonClick={handleOpenPopup}
      />

      <VideoBackgroundIntro
        video={data.intro.video}
        image={data.intro.image}
      />

      <Section
        direction="items-center flex-col lg:flex-row"
        preTitle={data.about.preTitle}
        preTitleClassname="!mb-[10px] !text-green-7"
        image={data.about.image}
        title={data.about.title}
        classname="py-[40px] lg:pt-[90px] lg:pb-[80px] bg-black-11 relative
        lg:px-[128px] anchor-block"
        contentClassname="xl:max-w-[600px]"
        innerContentClassname="
          gap-y-[30px] lg:gap-x-[60px] lg:justify-between relative z-[1]
          lg:px-0 [@media(min-width:1634px)]:!px-[32px]
        "
        imageClassname="lg:mr-[5%] lg:mt-[56px]"
        titleClassname="!mb-[32px] text-white"
        tags={data.about.tags}
        readMoreHref={data.about.readMoreHref}
        video={data.about.video}
        avatar={data.about.avatar}
        content={data.about.content}
        whiteParagraphText
        animated
        id="about"
        lens={
          <svg
            width="636"
            height="1101"
            viewBox="0 0 1279 988"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-[-100px] bottom-[-400px] hidden lg:block z-[1] pointer-events-none"
          >
            <g
              style={{ mixBlendMode: 'hard-light' }}
              opacity="0.3"
              filter="url(#filter0_f_5679_29194)"
            >
              <ellipse
                cx="639.565"
                cy="494.199"
                rx="373.466"
                ry="158.163"
                transform="rotate(-22.5314 639.565 494.199)"
                fill="#2E42FF"
              />
            </g>
            <defs>
              <filter
                id="filter0_f_5679_29194"
                x="0.343079"
                y="0.772766"
                width="1278.44"
                height="986.852"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood
                  floodOpacity="0"
                  result="BackgroundImageFix"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="144.46"
                  result="effect1_foregroundBlur_5679_29194"
                />
              </filter>
            </defs>
          </svg>
        }
      />

      <Loop
        id="first-loupe"
        imageClassName="
          rounded-[27px] [@media_((max-width:1440px)_and_(min-width:1280px))]:rounded-r-[0px]
        "
        onButtonClick={handleOpenPopup}
        description={data.loupe.description}
        wrapperClassName="lg:!px-[128px]"
        contentClassName="lg:!px-0 [@media(min-width:1634px)]:!px-[32px]"
      />

      <Section
        direction="items-center flex-col lg:flex-row"
        preTitle={data.clients.preTitle}
        preTitleClassname="!mb-0 !text-green-7"
        image={data.clients.image}
        title={data.clients.title}
        classname="py-[40px] lg:pt-[40px] lg:pb-[70px] bg-black-11 overflow-hidden
        lg:px-[128px]"
        innerContentClassname="
          gap-y-[30px] lg:gap-x-[60px]
          lg:px-0 [@media(min-width:1634px)]:!px-[32px]
        "
        titleClassname="!mb-[0] text-white"
        tags={data.clients.tags}
        readMoreHref={data.clients.readMoreHref}
        video={data.clients.video}
        avatar={data.clients.avatar}
        content={data.clients.content}
        animated
        id="clients"
      >
        <div
          className="
          h-full w-[calc(100%+32px)] min-h-[40px] flex items-end
          justify-center relative mobile:w-full bg-black-11
          [@media(max-height:850px)]:items-start
        "
        >
          <div
            className="
            w-full mobile:w-auto [@media_((max-height:850px)_and_(min-width:768px))]:pt-[30px]
            [@media_((min-width:1024px)_and_(max-height:850px))]:pt-0
          "
          >
            <ClientsSwiper
              slides={data.clients.clientsSwiper.slides}
              className="hot-lead-b"
              breakpoints={{
                1280: {
                  slidesPerView: 6,
                },
                768: {
                  slidesPerView: 5,
                },
                496: {
                  slidesPerView: 3,
                },
                400: {
                  slidesPerView: 2,
                },
              }}
            />
          </div>
        </div>
      </Section>

      <Services
        className="bg-black-11 relative overflow-x-clip lg:px-[128px] anchor-block"
        innerClassName="[@media(min-width:1634px)]:px-[32px] lg:px-0"
        withLens
        data={data.services}
        animated
      />

      <section className="w-full anchor-block">
        <ContainerWithAside wrapperClassName="mobile:py-[120px] overflow-hidden">
          <JourneySection
            titleSlot={
              <FadeAnimationContainer id="journey-title-anim">
                <Typography
                  variant="preTitle"
                  component="h2"
                  className="text-green-7 font-medium"
                >
                  {data.journey.title}
                </Typography>
              </FadeAnimationContainer>
            }
            phases={data.journey.phases}
            themeColor="dark"
            innerCardsTitleComponent="h3"
          />
        </ContainerWithAside>
      </section>

      <Cases
        data={casesList}
        viewAllCardData={cases.cases.viewAllCard}
        withButton={false}
        wrapperClassName="mobile:!pb-[40px] lg:pb-[40px] lg:px-[128px] anchor-block"
        isExpandable
        themeColor={themeColors.PURPLE}
      />

      <IndustriesBanner
        wrapperClass="bg-black-11"
        contentClassname="bg-black-11"
        titleClassname="!text-green-7"
        linkTitleClassname="text-white"
      />

      {/*FEEDBACKS*/}
      <section className="w-full anchor-block scroll-mt-[15px]">
        <ContainerWithAside wrapperClassName="relative overflow-hidden">
          <FadeAnimationContainer id="feedbacks">
            <FeedbacksSwiperSection
              titleSlot={
                <Typography
                  component="h2"
                  variant="preTitle"
                  className="z-[5] relative"
                >
                  {data.quotes.title}
                </Typography>
              }
              list={data.quotes.list}
              videoUrl={data.quotes.video.url}
            />
            <div
              className="absolute inset-x-0 top-[4%] h-[200px] xl-1:h-[400px] xl-1:top-[0]
            bg-gradient-to-b from-black-11 to-transparent z-[1] pointer-events-none"
            />
            <div
              className="absolute inset-x-0 bottom-0 h-[250px] xl-1:h-[300px] 
            bg-gradient-to-t from-black-11 z-[1] pointer-events-none"
            />
          </FadeAnimationContainer>
        </ContainerWithAside>
      </section>

      <CollapsableBanner
        contactUsButtonGradient="bg-conic-gradient-green"
        animated
        wrapperClassName="lg:px-[128px]"
      />
    </>
  );
};
