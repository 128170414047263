import { cn } from '@/helpers/tailwind';
import { IContainerWithAsideProps } from '@/atoms/02-layouts/ContainerWithAside/index.types';

export const ContainerWithAside = ({ wrapperClassName, containerClassName, children }: IContainerWithAsideProps) => {
  return (
    <div
      className={cn(`w-full flex justify-center py-[40px] mobile:py-[80px] px-base lg:px-[128px]`, {
        [`${wrapperClassName}`]: wrapperClassName,
      })}
    >
      <div
        className={cn(`w-full max-w-[1184px] medium-screen:max-w-[1376px]`, {
          [`${containerClassName}`]: containerClassName,
        })}
      >
        {children}
      </div>
    </div>
  );
};
