'use client';
import { IServiceCardProps, ServiceCard } from '@/components/Common/Services/Card';
import { Lens } from '@/components/Common/Services/Lens';
import { useScreenSize } from '@/hooks/useScreenSize';
import { BREAKPOINTS } from '@/constants/breakpoints';
import { FadeAnimationContainer } from '@/molecules/03-decorations/FadeAnimationContainer';
import clsx from 'clsx';

interface IProps {
  data: {
    title: string;
    cards: Omit<IServiceCardProps, 'index' | 'isTablet'>[];
  };
  withLens?: boolean;
  animated?: boolean;
  className: string;
  innerClassName?: string;
}

export const Services = ({ data, withLens, className, animated, innerClassName }: IProps) => {
  const { width } = useScreenSize();

  const titleContent = <h3 className="intro-pre-title mb-xs lg-2:!mb-[101px] !text-green-7">{data.title}</h3>;

  return (
    <section
      id="services"
      className={clsx('scroll-mt-[116px]', {
        [`${className}`]: className,
      })}
    >
      {withLens && <Lens />}

      <div
        className={clsx('container mx-auto pb-[40px] lg-2:pb-[240px] z-[1] relative xl-plus:px-[32px]', {
          [`${innerClassName}`]: innerClassName,
        })}
      >
        {animated ? (
          <FadeAnimationContainer id="service-title-anim">{titleContent}</FadeAnimationContainer>
        ) : (
          titleContent
        )}
        {data.cards.map((card, cardIndex) => {
          return (
            <ServiceCard
              key={card.id}
              {...card}
              index={cardIndex}
              isTablet={width < BREAKPOINTS['lg-2']}
            />
          );
        })}
      </div>
    </section>
  );
};
